import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Layout from "@hoc/Layout";
import ContainerResolver from "@containers/resolver";
import useSound from "use-sound";
import PromoCard from "@components/PromoCard";

import zen from "../assets/sounds/zen-master.mp3";
import Lightbox from "@components/Lightbox";
import Seo from "@components/Seo";

export default function Page({
  data: { page, global },
  pageContext,
  location,
}) {
  /* eslint-disable no-unused-vars */
  const [play, { stop }] = useSound(zen);

  useEffect(() => {
    play();
  }, []);

  return (
    <Layout locale={pageContext.locale}>
      <Seo {...{ ...page.seo, lang: pageContext.locale }} />
      <PromoCard {...global} />
      <Lightbox />
      <ContainerResolver
        {...{
          locale: pageContext.locale,
          ...page,
          global,
          twitchSchedule: pageContext?.twitchSchedule,
        }}
      />
    </Layout>
  );
}

export const query = graphql`
  query getAnglerPage($id: String, $locale: String) {
    global: datoCmsAnglerGlobalSetting(locale: { eq: $locale }) {
      usePromo
      promoText
      promoTitle
      promoImage {
        gatsbyImageData(width: 800, placeholder: DOMINANT_COLOR)
        alt
      }
      promoButtons {
        buttonLink
        buttonText
        isExternal
      }
      guideIcon {
        url
      }
      newsIcon {
        url
      }
    }
    page: datoCmsAnglerPage(id: { eq: $id }, locale: { eq: $locale }) {
      seo {
        description
        title
        twitterCard
        image {
          url
        }
      }
      pageName
      pageSlug
      id
      blades {
        ... on DatoCmsAnglerParallaxHero {
          ...ParallaxHero
        }
        ... on DatoCmsAnglerParallaxHeroV2 {
          ...ParallaxHero2
        }
        ... on DatoCmsAnglerHero {
          ...Hero
        }
        ... on DatoCmsAnglerImageBlade {
          ...ImageBlade
        }
        ... on DatoCmsAnglerColorBlade {
          ...ColorBlade
        }
      }
    }
  }
`;
